import React, {useEffect, useRef, useState} from "react";
import {Button, Input} from "antd";
import {
    SearchOutlined,
    DoubleLeftOutlined
} from '@ant-design/icons';


declare var google: any;
// @ts-ignore
const gtag = window.gtag;

const LAST_ADDRESS = 'last_address';
const current_address_value = window.localStorage.getItem(LAST_ADDRESS);
let current_address_obj: null | any = null;
if (current_address_value) {
    try {
        current_address_obj = JSON.parse(current_address_value || '');
    } catch (e) {

    }

}


const searchOptions = {
    location: new google.maps.LatLng(32.0880577, 34.7272056),
    types: ['address'],
    componentRestrictions: {country: 'IL'}
};

export const SearchBar = (props: { onSelect: (address) => void; }) => {
    const inputEl = useRef<Input | null>(null);
    const [value, setValue] = useState('');
    const [searchVisible, setSearchVisible] = useState(false);
    let onSelect = props.onSelect;

    useEffect(() => {
        if (inputEl.current) {
            let autocomplete = new google.maps.places.Autocomplete(inputEl.current.input, {...searchOptions});
            autocomplete.setFields(['address_components', 'formatted_address', 'geometry']);
            google.maps.event.addListener(autocomplete, 'place_changed', () => {
                let place = autocomplete.getPlace();

                if (place.name) {
                    // User clicked enter with no completed address
                    if (current_address_obj && current_address_obj.formatted_address === place.name) {
                        // Same address as last, user clicked enter after a correct completion
                        onSelect(current_address_obj);
                        setValue(current_address_obj.formatted_address);
                    } else {
                        // Wrong enter click
                        setValue('');
                    }
                } else if (place.formatted_address) {
                    // Correct google result
                    onSelect(place);
                    setValue(place.formatted_address);
                    window.localStorage.setItem(LAST_ADDRESS, JSON.stringify(place));
                } else {
                    // Should not happen - error
                    setValue('');
                }
            })
        }
    }, []);

    let shouldDisplaySearch = (searchVisible || !current_address_obj || !current_address_obj.formatted_address);
    return <div>
         <div style={{display:  shouldDisplaySearch ? 'block' : 'none'}}>
            <button
                className="clear-button"
                onClick={() => setValue('')}
            >
                x
            </button>
            <Input
                id={'autocomplete-input'} value={value} onChange={(e) => setValue(e.target.value)}
                placeholder={'הכניסו את הכתובת שלכם...'} ref={inputEl}/>
        </div>


        {current_address_obj && current_address_obj.formatted_address &&
        <div className={'fast-search-container'}>
            <Button onClick={() => {
                props.onSelect(current_address_obj);
                if (process.env.NODE_ENV === 'production') {
                    gtag('event', 'fast_search', {
                        'event_category': 'Search Address',
                        'event_label': current_address_obj.formatted_address,
                        'value': current_address_obj.formatted_address
                    });
                }
            }}
                    size={'large'} icon={<DoubleLeftOutlined />}
                    type={'primary'}>{current_address_obj.formatted_address}</Button>
            <div className={'fast-search-label rtl'}>חיפוש מהיר:</div>
            {!shouldDisplaySearch &&
            <div className={'rtl switch-address'} onClick={() => setSearchVisible(true)}>חפש כתובת אחרת<SearchOutlined /></div>
            }
        </div>
        }

    </div>
}