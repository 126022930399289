import React, {Reducer, useEffect, useReducer, useState} from 'react';
import './App.css';
import {DateTime} from 'luxon';
import {
    MailOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import {ReactComponent as MessageSvg} from './grow-shop.svg';
import {SearchBar} from "./SearchBar";
import {Avatar, Card, Divider, Modal, Skeleton} from "antd";
import Icon from '@ant-design/icons';

import ampm from './images/ampm.png'
import ampm_big from './images/ampm_big.jpg'
import eden from './images/eden.jpg'
import rami from './images/rami.jpg'
import rami_big from './images/rami_big.png'
import shufersal from './images/shufersal.png'
import shufersal_big from './images/shufersal_big.jpeg'
import supertlv from './images/supertlv.jpg'
import supertlv_big from './images/supertlv_big.png'
import tivtaam from './images/tivtaam.jpg'
import tivtaam_big from './images/tivtaam_big.jpg'
import victory from './images/victory.png'
import victory_big from './images/victory_big.png'
import ybitan from './images/ybitan.png'
import yuda from './images/yuda.png'
import carmella from './images/carmella.svg'
import carmella_big from './images/caremella_big.png'
import mega from './images/mega.jpg'
import mega_big from './images/mega_big.png'
import keshet from './images/keshet.png'
import keshet_big from './images/keshet.png'
import mck from './images/mck.png'
import mck_big from './images/mck_big.png'
import shukcity from './images/shukcity.jpeg'
import shukcity_big from './images/shukcity_big.png'
import shookit from './images/shookit.png'
import shookit_big from './images/shookit_big.png'


// @ts-ignore
const gtag = window.gtag;

const avatarMap = {
    'סופר יודה': {small: yuda, large: yuda},
    'שופרסל': {small: shufersal, large: shufersal_big},
    'רמי לוי': {small: rami, large: rami_big},
    'AM:PM': {small: ampm, large: ampm_big},
    'טיב טעם': {small: tivtaam, large: tivtaam_big},
    'יינות ביתן': {small: ybitan, large: ybitan},
    'עדן טבע': {small: eden, large: eden},
    'ויקטורי': {small: victory, large: victory_big},
    'הצרכניה': {small: supertlv, large: supertlv_big},
    'כרמלה': {small: carmella, large: carmella_big},
    'מגה': {small: mega, large: mega_big},
    'קשת טעמים': {small: keshet, large: keshet_big},
    'מחסני השוק': {small: mck, large: mck_big},
    'שוק העיר': {small: shukcity, large: shukcity_big},
    'שוקיט': {small: shookit, large: shookit_big},
};

type ShopMeta = {
    name: string
    phone: string
    website: string
}

type Shop = {
    metadata: ShopMeta;
    loading: boolean;
    error: boolean;
    available: DateTime
    earliestEnd: DateTime
    numToday: number;
    numTomorrow: number;
    nextDeliveryDate: DateTime;
};

type ShopDynamicData = {
    [shop: string]: {
        loading: false;
        error: false;
        available: false;
    }
}


// const parseAddressString= (addressStr: string) => {
//     let splitted = addressStr.split(", ");
//     let city = splitted[1];
//     let streetAndNum = splitted[0];
//     let numRE = /\d+(?:,)$/g;
//     let streetNums = (streetAndNum ? streetAndNum.match(numRE) : undefined);
//     let streetNum = (streetNums ? streetNums[0] : "")
//     let nameRE = /^.+(?:\s,)$/g;
//     let streetName = (streetAndNum ? streetAndNum.match(nameRE)[0] : '');
//     let res = {
//         address_components: [
//             {long_name: streetNum, short_name: streetName, types: ['street_number']},
//             {long_name: streetName, short_name: streetName, types: ['route']},
//             {long_name: city, short_name: city, types: ['locality', 'political']},
//             {long_name: 'מחוז תל אביב', short_name: 'מחוז תל אביב', types: ['administrative_area_level_1', 'political']},
//             {long_name: 'ישראל', short_name: 'IL', types: ['country', 'political']}],
//         formatted_address: addressStr + ', ישראל',
//         html_attributions: []};
//     return res;
// };

function App() {
    const [shops, setShops] = useReducer<Reducer<Map<string, Shop>, any>>(
        (state, newState) => ({...state, ...newState}),
        new Map()
    );

    const [place, setPlace] = useState<{ formatted_address: string; address_components: any } | null>(null);
    const [didSearch, setDidSearch] = useState(false);
    const [aboutUsVisible, setAboutUsVisible] = useState(false);

    useEffect(() => {
        fetch('/api/list_shops')
            .then(resp => resp.json()
                .then(x => {
                    setShops(x);
                }));
    }, []);

    useEffect(() => {
        if (place !== null) {
            handleSelect();

            if (process.env.NODE_ENV === 'production') {
                gtag('event', 'search_address', {
                    'event_category': 'Search Address',
                    'event_label': place.formatted_address,
                    'value': place.formatted_address
                });
            }
        }
    }, [place]);

    const updateShop = (shop: string, props) => {
        setShops({[shop]: {...shops[shop], ...props}});
    };

    const getNextDeliveryText = (shop: Shop) => {
        if (shop.numToday > 1) {
            return 'זמני משלוח נוספים זמינים באותו היום'
        } else if (shop.numTomorrow > 0) {
            return 'זמני משלוח נוספים זמינים רק למחרת'
        } else if (shop.nextDeliveryDate) {
            let days = Math.ceil(shop.nextDeliveryDate.diff(shop.available, 'days').days);
            return `המשלוח הבא זמין ${days} ימים לאחר מכן`
        } else {
            return 'אין כרגע זמני משלוח נוספים'
        }
    };

    const getCardContent = (shop: Shop) => {
        let content = <br/>;
        if (shop.loading) {
            content = <Skeleton.Input active={true} size={'small'}/>
        } else {
            if (shop.error) {
                content = <span className={'error rtl'}><br/>{shop.error}</span>
            } else {
                if (shop.available) {
                    let local = shop.available.setLocale("he");
                    let localEnd = shop.earliestEnd.setLocale("he");
                    content = (
                        <div>
                            <div className={'available-container'}>
                                <span className={'available-label'}> המשלוח הבא: </span>
                                <div className={'available-date-container'}>
                                    <span className={'available-date'}>{local.toFormat('DDD')}</span>
                                    <div>
                            <span className={'available-date'}>
                                {`יום ${local.toFormat('ccccc')}`}&nbsp;
                            </span>
                                        <span className={'available-date'}>
                                {`${localEnd.toFormat('t')} - ${local.toFormat('t')}`}
                            </span>
                                    </div>
                                </div>

                            </div>
                            <div className={'next-delivery-text'}>
                                {getNextDeliveryText(shop)}
                            </div>
                        </div>
                    )
                }

            }
        }
        return <div className={'delivery-content rtl'}>{content}</div>
    };

    const handleSelect = () => {
        // @ts-ignore
        window.document.activeElement.blur();
        setDidSearch(true);
        for (let currentShop of Object.keys(shops)) {
            updateShop(currentShop, {loading: true, available: undefined, error: undefined});
            fetch('/api/deliveries', {
                method: 'POST',
                body: JSON.stringify({address: place, shop: currentShop}),
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(resp => resp.json().then(x => {
                updateShop(currentShop, {
                    available: x.earliest ? DateTime.fromISO(x.earliest) : undefined,
                    earliestEnd: x.earliest_end ? DateTime.fromISO(x.earliest_end) : undefined,
                    numToday: x.deliveries_in_earliest_date,
                    numTomorrow: x.deliveries_in_day_after,
                    nextDeliveryDate: x.next_delivery_date ? DateTime.fromISO(x.next_delivery_date) : undefined,
                    error: x.error,
                    loading: false
                });
            }));
        }


    };

    let sorted = Object.entries(shops).filter(([shopName, s]) => s.loading || s.error || s.available)
        .sort(([shopNameA, sA], [shopNameB, sB]) => {
            if (!sA.available) {
                return 2200
            } else if (!sB.available) {
                return -2200
            } else {
                return sA.available.diff(sB.available)
            }
        });

    let earliestShop: Shop | undefined = undefined;
    if (sorted[0]) {
        earliestShop = sorted[0][1]
    }

    return <div className="App ">
        <span onClick={() => setAboutUsVisible(true)}
              className={'about-us-link'}><QuestionCircleOutlined/>קצת עלינו</span>
        <div className={'header'}>
            <Icon component={MessageSvg}/><span className={'title rtl'}>בואו נמצא לכם משלוח</span>
            <div className={'sub-title rtl'}>בזריז.</div>
        </div>
        <div className={'search-bar'}>
            <SearchBar onSelect={(place) => setPlace(place)}/>
        </div>
        <div className={'gallery'}>
            {didSearch ? sorted.map(([shopName, s]: [string, Shop], idx) => {
                    let isEarliest = false;
                    if (earliestShop && earliestShop.available && s.available && earliestShop.available.equals(s.available)) {
                        isEarliest = true;
                    }

                    return <Card
                        key={s.metadata.name}
                        title={s.metadata.name}
                        extra={isEarliest ?
                            <div className="ribbon"><span className={'rtl'}>הכי מוקדם!</span></div> : null}
                        style={{
                            opacity: s.error && !s.loading ? 0.6 : 1,
                            textAlign: 'left',
                            margin: '12px 12px',
                            width: 300,
                            boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 2px 0 rgba(0, 0, 0, 0.12), 0 2px 12px 2px rgba(0, 0, 0, 0.09)'
                        }}>
                        <Card.Meta

                            avatar={<Avatar src={avatarMap[s.metadata.name].small}/>}
                            description={<div><a style={{whiteSpace: 'nowrap'}} rel="noopener noreferrer" target={'_blank'}
                                                 href={s.metadata.website}>{s.metadata.website}</a>
                                <div className={'phone-number'}><a href={`tel:+${s.metadata.phone}`}>{s.metadata.phone}</a>
                                </div>
                            </div>}
                        />
                        {getCardContent(s)}
                    </Card>;
                }
            ) : <div className={'logos'}>
                <Divider/>
                {Object.keys(avatarMap).map(x => {
                    return <img key={x} alt={x} className={'logo-img'} src={avatarMap[x].large}/>
                })}</div>}

        </div>
        <div className={'footer'}>
            <div className={'sub-title rtl'}>אהבתם את בזריז? יש לכם הצעה לשיפור?</div>
            <MailOutlined/>
            <div style={{display: 'inline'}} className={'rtl sub-title'}><a
                href={'mailto:bazariz.contact@gmail.com'}>bazariz.contact@gmail.com</a> - ספרו לנו ב
            </div>
            <div style={{marginTop: 20}} className={'rtl footer-text'}>
                מפעילי האתר לא מתחייבים על אמינות המידע המוצג.<br/>
                יש להתייחס למידע בתור המלצה בלבד, ולפנות לאתרי החנויות הרשמיים לקבלת פרטים מדויקים.
            </div>
            <div className={'footer-text'} style={{marginTop: 20}}>Icons made by <a
                href="https://www.flaticon.com/authors/freepik"
                title="Freepik">Freepik</a> from <a
                href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            <Modal
                title="קצת עלינו"
                visible={aboutUsVisible}
                footer={null}
                onOk={() => setAboutUsVisible(false)}
                onCancel={() => setAboutUsVisible(false)}
            >
                <div className={'rtl'}>
                    <p>
                        אנחנו צוות ״בזריז״, נעים להכיר!
                        התחלנו בתור שלושה חבר׳ה שחיפשו דרך לעזור לעולם אפילו בקצת במשבר הנוכחי. אז חיסון לא מצאנו, וגם
                        לא
                        פתרון לאבטלה, אבל היי, לפחות אנחנו לא צריכים לחכות שבועיים למשלוח!
                        <br/><br/>
                        אנחנו מקווים שהאתר יעזור לכמה שיותר אנשים להשיג מצרכים בקלות ובזמן, להמנע מביקורים מיותרים
                        בסופרמרקט, ובמיוחד לאלו שהשהות שם היא מסוכנת עבורם.
                        אנחנו מקווים גם לעזור דרך האתר לסופרמרקטים, שנתקלים בעומס רב בתקופה הזו, להנגיש את שירותיהם.
                        <br/><br/>
                        האתר בהרצה, ונשמח שתספרו לנו על כל תקלה או הצעה לשיפור שיש לכם.
                        אנחנו ממליצים תמיד לוודא את המידע גם באתרים הרשמיים של הסופרמרקטים.
                        <br/><br/>
                        תודה ורק בריאות,<br/>
                        צוות ״בזריז״
                    </p>
                    <Divider/>

                    <div style={{display: 'inline'}} className={'rtl sub-title'}><a
                        href={'mailto:bazariz.contact@gmail.com'}>bazariz.contact@gmail.com</a>
                    </div>
                </div>
            </Modal>
        </div>
    </div>
        ;
}

export default App;
